import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Divider, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DetailedActionTable } from './DetailedActionTable';

/**
 * DetailedActionModal component
 *
 * @component
 * @param {Boolean} expendedActions - Boolean to check if the actions are expended
 * @param {Function} setExpendedActions - Function to set the expended actions
 * @param {Boolean} addButton - Boolean to check if the add button is visible
 * @param {Array} displayedData - Array of displayed tasks
 * @param {String} projectTaskId - Id of the default project task
 * @param {Function} setPurposeArg - Function to set the purpose argument
 * @param {Function} setVisible - Function to set the visibility of the modal
 * @param {Function} setDataToModal - Function to set the data to the modal
 * @param {Function} ClickableTitle - Function to set the clickable title
 * @returns {React.Component} <DetailedActionModal />
 */
export const DetailedActionModal = ({
  expendedActions,
  setExpendedActions,
  addButton,
  displayedData,
  projectTaskId,
  setPurposeArg,
  setVisible,
  setDataToModal,
  ClickableTitle
}) => {
  return (
    <Modal
      visible={expendedActions}
      centered
      width="80%"
      className="actions-table"
      onCancel={() => setExpendedActions(false)}
      closable={false}
      footer={null}
    >
      <Card
        title={ClickableTitle(false)}
        extra={
          addButton && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setPurposeArg('createMatrice');
                setVisible(true);
              }}
            >
              Action
            </Button>
          )
        }
      >
        <Divider />
        <DetailedActionTable
          data={displayedData}
          projectTaskId={projectTaskId}
          setPurposeArg={setPurposeArg}
          setDataToModal={setDataToModal}
          setVisible={setVisible}
        />
      </Card>
    </Modal>
  );
};

DetailedActionModal.propTypes = {
  expendedActions: PropTypes.bool,
  setExpendedActions: PropTypes.func,
  addButton: PropTypes.bool,
  displayedData: PropTypes.arrayOf(PropTypes.shape({})),
  projectTaskId: PropTypes.string,
  setPurposeArg: PropTypes.func,
  setVisible: PropTypes.func,
  setDataToModal: PropTypes.func,
  ClickableTitle: PropTypes.func
};

DetailedActionModal.defaultProps = {
  expendedActions: false,
  setExpendedActions: () => {},
  addButton: false,
  displayedData: [],
  projectTaskId: '',
  setPurposeArg: () => {},
  setVisible: () => {},
  setDataToModal: () => {},
  ClickableTitle: () => {}
};
