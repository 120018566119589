import React from 'react';
import setIconStatus from './setIconStatus';

export const listVersion = ['V0', 'V1', 'VX'];

export const listTypeOfReport = [
  'project_state',
  'evolution_project',
  'federation',
  'planning',
  'gap',
  'meeting_report',
  'workload_plan'
];

export const listTypeOfAdminsReport = ['workload_plan'];

export const versionToIndex = {
  V0: 0,
  V1: 1,
  VX: 2
};
export const listItems = [
  'what',
  'why',
  'prior',
  'actors',
  'context',
  'cost',
  'risks',
  'planning',
  'utility',
  'plan_com'
];
export const listObjectForItems = {
  what: null,
  why: null,
  prior: 'studies',
  actors: 'actors_list',
  context: 'factors',
  cost: 'costs',
  risks: 'risk_list',
  planing: 'deliverables',
  utility: 'deliverables',
  plan_com: 'deliverables_plan_com'
};
export const typeOfComparaison = {
  V0toV1: (
    <div>
      <span>{setIconStatus(null, 'V0', false, 19)}</span>
      <span style={{ margin: '0px 10px 0px 5px' }}>vs</span>
      <span>{setIconStatus(null, 'V1', false, 19)}</span>
    </div>
  ),
  V0toVX: (
    <div>
      <span>{setIconStatus(null, 'V0', false, 19)}</span>
      <span style={{ margin: '0px 10px 0px 5px' }}>vs</span>
      <span>{setIconStatus(null, 'VX', false, 19)}</span>
    </div>
  )
};
export const listIDForGetIndicators = {
  project_state: [
    'project_stateindicators',
    'project_staterisk',
    'project_stategantt_planning',
    'project_stategantt_federation'
  ],
  evolution_project: [
    'evolution_projecttime',
    'evolution_projectcost',
    'evolution_projectutility',
    'evolution_projectfederation'
  ],
  federation: ['federationgantt_federation'],
  planning: ['planninggantt_planning'],
  gap: ['gapgantt_gap'],
  meeting_report: []
};

export const getIndexByVersionSelected = (status, data) => {
  switch (status) {
    case 'V0':
      return 0;
    case 'V1':
      return 1;
    case 'VX':
      return data.length - 1;
    default:
      return 0;
  }
};
