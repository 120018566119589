import React from 'react';
import PropTypes from 'prop-types';
import { Form, DatePicker, Divider } from 'antd';
import RadioForChooseTypeOfFile from '../widgetsReporting/RadioForChooseTypeOfFile';

const { RangePicker } = DatePicker;
const MeetingReport = ({ keyTab }) => {
  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        height: 'auto'
      }}
    >
      <RadioForChooseTypeOfFile keyTab={keyTab} disabledFormats={['xlsx']} />
      <Divider />
      <Form.Item name={['dates']}>
        <RangePicker />
      </Form.Item>
    </div>
  );
};
MeetingReport.propTypes = {
  keyTab: PropTypes.string
};

MeetingReport.defaultProps = {
  keyTab: undefined
};

export default MeetingReport;
