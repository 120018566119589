import domtoimage from 'dom-to-image';
import moment from 'moment';
import { checkActionActor } from '../../../../routes/Projects/Projects/utilsProject/ManagePlanning/utils';

/**
 * French translation for the scale of the Gantt chart.
 */
export const ganttTranslations = {
  date: {
    month_full: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ],
    month_short: [
      'Jan',
      'Fév',
      'Mar',
      'Avr',
      'Mai',
      'Jun',
      'Jul',
      'Aoû',
      'Sep',
      'Oct',
      'Nov',
      'Déc'
    ],
    day_full: [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi'
    ],
    day_short: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
  },
  labels: {}
};

/**
 * Function to get the tooltip of the Gantt chart.
 *
 * @function
 * @param {Function} t - The translation function.
 * @returns {Function} - The tooltip function.
 */
export const getTooltip = (t) => (start, end, task) => {
  return `${task.text}<br/>${
    task.type !== 'milestone'
      ? `${t('projects.form.start_date')} : ${moment(start).format(
          'dddd DD-MM-YYYY'
        )}<br/>`
      : ''
  }${t('projects.form.due_date')} : ${moment(end).format('dddd DD-MM-YYYY')}`;
};

/**
 * Function to lighten a hexadecimal color.
 *
 * @function
 * @param {string} hex - The hexadecimal color.
 * @param {number} amount - The amount to lighten.
 * @returns {string} - The new hexadecimal color.
 */
const lightenHexColor = (hex, amount) => {
  // Retire le symbole '#' si présent
  const formattedHex = hex.replace('#', '');

  // Convertit la couleur hexadécimale en un nombre entier
  const color = parseInt(formattedHex, 16);

  // Ajoute l'offset pour éclaircir
  let r = (color >> 16) + amount;
  let g = ((color >> 8) & 0x00ff) + amount;
  let b = (color & 0x0000ff) + amount;

  // S'assure que les valeurs RGB sont dans la plage correcte
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  // Convertit les valeurs RGB de retour en hexadécimal
  const newColor = (r << 16) | (g << 8) | b;
  return `#${newColor.toString(16).padStart(6, '0')}`;
};

/**
 * Function to sort the data by start date.
 *
 * @function
 * @param {object[]} data - The data to sort.
 * @returns {object[]} - The sorted data.
 */
const sortData = (data) =>
  data.sort((a, b) => {
    return new Date(a.start_date) - new Date(b.start_date);
  });

/**
 * Function to format the data for the Gantt chart.
 *
 * @function
 * @param {object[]} data - The data to format.
 * @param {string} actorId - The actor ID to filter the data.
 * @returns {object[]} - The formatted data.
 */
export const formatData = (data, actorId) => {
  const formattedData = data
    .filter(
      (item) => item.planning && (!actorId || checkActionActor(item, actorId))
    )
    .map((item) => {
      const comonData = {
        id: item._id,
        text: item.description,
        parent: !actorId && item.planning.macro_task?._id,
        color: item.planning.color,
        readonly: true
      };
      if (!item.planning.start_date)
        return {
          ...comonData,
          start_date: moment(item.planning.due_date).format('YYYY-MM-DD'),
          type: 'milestone'
        };
      return {
        ...comonData,
        start_date: moment(item.planning.start_date).format('YYYY-MM-DD'),
        end_date: moment(item.planning.due_date).format('YYYY-MM-DD'),
        progress: item.planning.percentage / 100,
        open: true,
        progressColor: lightenHexColor(item.planning.color, 0x22),
        type: item.planning.action_type === 'MACRO_TASK' ? 'project' : 'task'
      };
    });

  return sortData(formattedData);
};

/**
 *
 * @param {object[]} tasks - The tasks to filter.
 * @param {Date[]} dates - The dates to filter.
 * @returns {object[]} - The filtered tasks.
 */
export const filterDataByDates = (tasks, dates) => {
  if (!dates || !dates.length) return tasks;

  return tasks.filter((task) => {
    if (task.type === 'milestone')
      return moment(task.start_date).isBetween(dates[0], dates[1], 'day', '[]');
    return (
      moment(task.start_date).startOf('day') <=
        moment(dates[1]).startOf('day') &&
      moment(task.end_date).startOf('day') >= moment(dates[0]).startOf('day')
    );
  });
};

/**
 * Function to take a screenshot of the Gantt chart.
 *
 * @function
 * @param {Function} message - The error message function.
 * @returns {void}
 */
export const getScreenShotOfChart = async (message) => {
  try {
    const result = await domtoimage.toPng(
      document.querySelector('#planning_gantt')
    );
    const a = document.createElement('a');
    a.href = result;
    a.download = 'gantt.png';
    a.click();
  } catch (error) {
    message(error);
  }
};
