import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { listVersion } from '../../../utils/constants/constantes';
import setIconStatus from '../../../utils/constants/setIconStatus';

/**
 * VersionRadio component to select the version of the project to generate the report
 *
 * @component
 * @param {object} props - VersionRadio props
 * @param {string} props.value - Value
 * @param {function} props.onChange - On change function
 * @param {string} props.status - Status of the project. Should be empty for workload plan report
 * @returns {React.Component} <VersionRadio />
 */
export const VersionRadio = ({ value, onChange, status }) => {
  const { t } = useTranslation();

  return (
    <Radio.Group
      buttonStyle="solid"
      style={{
        margin: '10px 0px 10px 0px',
        width: '100%'
      }}
      value={value}
      onChange={onChange}
    >
      {listVersion.map((e) => (
        <Tooltip
          key={e}
          placement="topLeft"
          title={`${t('reporting.items_tooltip')} ${e === 'VX' ? 'V+' : e}`}
        >
          <Radio
            disabled={
              status && listVersion.indexOf(e) > listVersion.indexOf(status)
            }
            value={e}
          >
            {setIconStatus(null, e, false, 19)}
          </Radio>
        </Tooltip>
      ))}
    </Radio.Group>
  );
};

VersionRadio.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  status: PropTypes.string
};

VersionRadio.defaultProps = {
  value: null,
  onChange: () => {},
  status: ''
};
