import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, DatePicker, Divider } from 'antd';
import RadioForChooseTypeOfFile from '../widgetsReporting/RadioForChooseTypeOfFile';
import { VersionRadio } from '../widgetsReporting/VersionRadio';

const { RangePicker } = DatePicker;
const GapSelected = ({
  t,
  keyTab,
  version,
  current_status,
  actors_list,
  setselectedDates,
  setselectedActor
}) => {
  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        height: 'auto'
      }}
    >
      <RadioForChooseTypeOfFile keyTab={keyTab} />
      <Divider />
      <Form.Item name={['version']} initialValue={version}>
        <VersionRadio status={current_status} />
      </Form.Item>
      <Form.Item name={['dates']}>
        <RangePicker onChange={(range) => setselectedDates(range)} />
      </Form.Item>
      <Form.Item label={t('reporting.select_actor')} name={['actor']}>
        <Select
          allowClear
          onChange={(actor) =>
            actor ? setselectedActor(actor) : setselectedActor(null)
          }
        >
          {actors_list.map((actor) => (
            <Select.Option key={actor._id} value={actor._id}>
              {`${actor.group || ''} ${actor.first_name ||
                ''} ${actor.last_name || ''}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

GapSelected.propTypes = {
  t: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  current_status: PropTypes.string,
  keyTab: PropTypes.string,
  actors_list: PropTypes.oneOfType([PropTypes.array]),
  setselectedDates: PropTypes.func,
  setselectedActor: PropTypes.func
};
GapSelected.defaultProps = {
  current_status: null,
  actors_list: [],
  keyTab: null,
  setselectedDates: () => {},
  setselectedActor: () => {}
};

export default GapSelected;
