import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Select } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import {
  listTypeOfAdminsReport,
  listVersion
} from '../../utils/constants/constantes';
import { WorkloadPlan } from './TypeSelectedComponents/WorkloadPlan';
import {
  getProjectsCount,
  getWorkloadPlanData,
  handleReportChange
} from './utils';
import useFormatReportByType from './widgetsReporting/formatToXlsxByTypeOfReport/useFormatReportByType';

/**
 * Reporting component for admins.
 *
 * @component
 * @return {JSX.Element} - The reporting component for admins.
 */
export const AdminsReporting = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [form] = Form.useForm();
  const [reportingData, setReportingData] = useState({});
  const [loading, setLoading] = useState(false);

  const version = Form.useWatch('version', form) || listVersion[0];
  const typeOfReport = Form.useWatch('type', form) || listTypeOfAdminsReport[0];
  const selectedProjects = Form.useWatch('projects', form) || [];

  const formatByTypeOfReport = useFormatReportByType(typeOfReport);

  const handleSubmit = () => {
    formatByTypeOfReport(
      reportingData?.data?.projects.filter((project) =>
        selectedProjects?.length
          ? selectedProjects.includes(project._id)
          : reportingData?.options?.projects?.find(
              (option) => option.value === project._id && !option.disabled
            )
      ),
      { version }
    );
  };

  const onValuesChange = (change, values) => {
    handleReportChange(change, values, form, reportingData, setReportingData);
  };

  const listTypeOfReportComponent = {
    workload_plan: (
      <WorkloadPlan
        filtersOptions={reportingData.options}
        version={version}
        loading={loading}
      />
    )
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await getWorkloadPlanData(dispatchAPI);
        setReportingData(data);
        setLoading(false);
      } catch (error) {
        message(error);
        setLoading(false);
      }
    })();
  }, []);

  const projectCount = getProjectsCount(selectedProjects, reportingData);

  return (
    <>
      <PageHeaderCustom title={t('reporting.title')} />
      <ContentCustom
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Form
          onValuesChange={onValuesChange}
          onFinish={handleSubmit}
          initialValues={{
            type: listTypeOfAdminsReport[0],
            version: listVersion[0],
            extension_file: 'xlsx'
          }}
          form={form}
        >
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name={['type']}>
                <Select
                  defaultValue={listTypeOfAdminsReport[0]}
                  style={{ width: 250 }}
                >
                  {listTypeOfAdminsReport.map((el) => (
                    <Select.Option key={el} value={el}>
                      {t(`reporting.type.${el}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {listTypeOfReportComponent[typeOfReport]}
            <Col span={24}>
              <Button
                type="add"
                htmlType="submit"
                style={{ minHeight: 50, margin: 50 }}
                disabled={!projectCount}
              >
                {t('reporting.generate', {
                  count: projectCount
                })}
              </Button>
            </Col>
          </Row>
        </Form>
      </ContentCustom>
    </>
  );
};
