/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import headerByitems from './headerByItems';
import { getItemVersion } from '../../../Projects/Projects/utilsProject/ManageVersion/utils';
import { listVersion } from '../../../../utils/constants/constantes';

export const workloadPlan = (t, projects, version) => {
  const workbook = new Workbook();
  try {
    const worksheet = workbook.addWorksheet(
      `${t(`reporting.workload_plan.title`)} - ${version}`
    );
    worksheet.properties.defaultColWidth = 15;
    worksheet.properties.defaultRowHeight = 20;
    const newHeader = headerByitems(true);
    const header = newHeader.workload_plan.map(({ label, index }) =>
      t(label, { index })
    );

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '202364' },
        bgColor: { argb: '' }
      };
      cell.font = {
        bold: false,
        color: { argb: 'FFFFFF' },
        size: 11
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    const formatDeliverable = (deliverable, project) => {
      const actors = deliverable.planning.assigned_actors
        .slice(0, 10)
        .map((actor) =>
          actor.user
            ? actor.user.email
            : `${actor.first_name} ${actor.last_name}`
        );

      while (actors.length < 10) actors.push('');

      const groups = deliverable.planning.assigned_groups
        .slice(0, 10)
        .map((group) => group.name);

      while (groups.length < 10) groups.push('');

      return [
        project.perimeter.name,
        project.assignee.email,
        project.name,
        deliverable.description,
        deliverable.global_type,
        deliverable.planning.macro_task.description,
        t(
          `projects.form.deliverables.planning.${deliverable.planning.action_type.toLowerCase()}`
        ),
        deliverable.planning.workload,
        deliverable.planning.start_date &&
          moment(deliverable.planning.start_date).format('DD-MM-YYYY'),
        moment(deliverable.planning.due_date).format('DD-MM-YYYY'),
        t(`projects.form.deliverables.statuses.${deliverable.planning.status}`),
        deliverable.planning.percentage,
        ...actors,
        ...groups
      ];
    };

    let row;
    projects.forEach((project) => {
      getItemVersion(project.manage_planning, listVersion.indexOf(version))
        .content.filter(
          (deliverable) =>
            deliverable.planning &&
            deliverable.planning.action_type !== 'MACRO_TASK'
        )
        .forEach((deliverable) => {
          row = formatDeliverable(deliverable, project);
          if (row) worksheet.addRow(row);
        });
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${t('reporting.workload_plan.file_title')}_${moment().format(
      'DD-MM-YYYY'
    )}.xlsx`;
    a.click();
  });
};
