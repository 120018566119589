const headerByitems = (state) => {
  return {
    what: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'what',
        key: 'what'
      }
    ],
    why: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'why',
        key: 'why'
      }
    ],
    prior: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'studies.type',
        key: 'type'
      },
      {
        label: 'studies.denomination',
        key: 'denomination'
      },
      {
        label: 'studies.creation_date',
        key: 'creation_date'
      },
      {
        label: 'studies.description',
        key: 'description'
      }
    ],
    actors: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'actors_list.actor.first_name',
        key: 'first_name'
      },
      {
        label: 'actors_list.actor.last_name',
        key: 'last_name'
      },
      {
        label: 'actors_list.actor.is_active',
        key: 'is_active'
      },
      {
        label: 'actors_list.actor.code',
        key: 'code'
      },
      {
        label: 'actors_list.actor.role',
        key: 'role'
      },
      {
        label: 'actors_list.coefficient',
        key: 'coefficient'
      }
    ],
    groups: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'actors_list.group.name',
        key: 'name'
      },
      {
        label: 'actors_list.group.headcount',
        key: 'headcount'
      },
      {
        label: 'actors_list.group.code',
        key: 'code'
      },
      {
        label: 'actors_list.group.role',
        key: 'role'
      },
      {
        label: 'actors_list.group.actors',
        key: 'actors'
      },
      {
        label: 'actors_list.group.coefficient',
        key: 'coefficient'
      }
    ],
    context: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'factors.denomination',
        key: 'denomination'
      },
      {
        label: 'factors.type',
        key: 'type'
      },
      {
        label: 'factors.impact',
        key: 'impact'
      },
      {
        label: 'factors.description',
        key: 'description'
      }
    ],
    cost: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'costs.denomination',
        key: 'denomination'
      },
      {
        label: 'costs.type',
        key: 'type'
      },
      {
        label: 'costs.charge',
        key: 'charge'
      },
      {
        label: 'costs.cost_value',
        key: 'cost_value'
      },
      {
        label: 'costs.description',
        key: 'description'
      }
    ],
    risks: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'risk_list.number',
        key: 'number'
      },
      {
        label: 'risk_list.potential_risk',
        key: 'potential_risk'
      },
      {
        label: 'risk_list.type_of_risk',
        key: 'type_of_risk'
      },
      {
        label: 'risk_list.impact_effect',
        key: 'impact_effect'
      },
      {
        label: 'risk_list.criticality',
        key: 'criticality'
      },
      {
        label: 'risk_list.decision',
        key: 'decision'
      },
      {
        label: 'risk_list.measure_description',
        key: 'measure_description'
      }
    ],
    planning: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'deliverables.denomination',
        key: 'denomination'
      },
      {
        label: 'deliverables.planning.action_type',
        key: 'action_type'
      },
      {
        label: 'deliverables.global_type',
        key: 'global_type'
      },
      {
        label: 'deliverables.comment',
        key: 'comment'
      },
      {
        label: 'deliverables.planning.start_date',
        key: 'start_date'
      },
      {
        label: 'deliverables.planning.due_date',
        key: 'due_date'
      },
      {
        label: 'deliverables.planning.actors',
        key: 'actors'
      },
      {
        label: 'deliverables.planning.groups',
        key: 'groups'
      },
      {
        label: 'deliverables.planning.workload',
        key: 'workload'
      },
      {
        label: 'deliverables.planning.status',
        key: 'status'
      },
      {
        label: 'deliverables.planning.percentage',
        key: 'percentage'
      }
    ],
    utility: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'deliverables.denomination',
        key: 'denomination'
      },
      {
        label: 'deliverables.global_type',
        key: 'global_type'
      },
      {
        label: 'deliverables.utility.use_for',
        key: 'use_for'
      },
      {
        label: 'deliverables.utility.beneficiaries',
        key: 'beneficiaries'
      },
      {
        label: 'deliverables.utility.why',
        key: 'why'
      },
      {
        label: 'deliverables.utility.coefficient',
        key: 'coefficient'
      }
    ],
    plan_com: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'deliverables_plan_com.name',
        key: 'name'
      },
      {
        label: 'deliverables_plan_com.start_date',
        key: 'start_date'
      },
      {
        label: 'deliverables_plan_com.due_date',
        key: 'due_date'
      },
      {
        label: 'deliverables_plan_com.frequency',
        key: 'frequency'
      },
      {
        label: 'deliverables_plan_com.type',
        key: 'type'
      },
      {
        label: 'deliverables_plan_com.channel',
        key: 'channel'
      },
      {
        label: 'deliverables_plan_com.impact',
        key: 'impact'
      },
      {
        label: 'deliverables_plan_com.in_charge_of',
        key: 'in_charge_of'
      },
      {
        label: 'deliverables_plan_com.transmitter',
        key: 'transmitter'
      },
      {
        label: 'deliverables_plan_com.target',
        key: 'target'
      },
      {
        label: 'deliverables_plan_com.federation',
        key: 'federation'
      },
      {
        label: 'deliverables_plan_com.goal_description',
        key: 'goal_description'
      },
      {
        label: 'deliverables_plan_com.goal_action',
        key: 'goal_action'
      }
    ],
    manage_planning: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'deliverables.denomination',
        key: 'denomination'
      },
      {
        label: 'deliverables.planning.action_type',
        key: 'action_type'
      },
      {
        label: 'deliverables.global_type',
        key: 'global_type'
      },
      {
        label: 'deliverables.comment',
        key: 'comment'
      },
      {
        label: 'deliverables.planning.start_date',
        key: 'start_date'
      },
      {
        label: 'deliverables.planning.due_date',
        key: 'due_date'
      },
      {
        label: 'deliverables.planning.actors',
        key: 'actors'
      },
      {
        label: 'deliverables.planning.groups',
        key: 'groups'
      },
      {
        label: 'deliverables.planning.status',
        key: 'status'
      },
      {
        label: 'deliverables.planning.percentage',
        key: 'percentage'
      },
      {
        label: 'deliverables.utility.use_for',
        key: 'use_for'
      },
      {
        label: 'deliverables.utility.beneficiaries',
        key: 'beneficiaries'
      },
      {
        label: 'deliverables.utility.why',
        key: 'why'
      },
      {
        label: 'deliverables.utility.coefficient',
        key: 'coefficient'
      }
    ],
    gap: [
      ...(state
        ? [
            {
              label: 'version',
              key: 'version'
            }
          ]
        : []),
      {
        label: 'deliverables.denomination',
        key: 'denomination'
      },
      {
        label: 'deliverables.global_type',
        key: 'global_type'
      },
      {
        label: 'deliverables.comment',
        key: 'comment'
      },
      {
        label: 'deliverables.planning.start_date',
        key: 'start_date'
      },
      {
        label: 'deliverables.planning.due_date',
        key: 'due_date'
      },
      {
        label: 'deliverables.planning.actor',
        key: 'actor'
      },
      {
        label: 'deliverables.planning.status',
        key: 'status'
      },
      {
        label: 'deliverables.planning.percentage',
        key: 'percentage'
      },
      {
        label: 'deliverables.events.modified_at',
        key: 'created_at'
      },
      {
        label: 'deliverables.events.motif',
        key: 'comment'
      },
      {
        label: 'deliverables.events.old_start_date',
        key: 'old_start_date'
      },
      {
        label: 'deliverables.events.old_due_date',
        key: 'old_due_date'
      }
    ],
    workload_plan: [
      {
        label: 'projects.details.perimeter',
        key: 'perimeter'
      },
      {
        label: 'projects.project_manager',
        key: 'assignee'
      },
      {
        label: 'reporting.workload_plan.columns.project',
        key: 'name'
      },
      {
        label: 'reporting.workload_plan.columns.action',
        key: 'action'
      },
      {
        label: 'projects.form.deliverables.global_type',
        key: 'type'
      },
      {
        label: 'projects.form.deliverables.planning.macro_task',
        key: 'macro_task'
      },
      {
        label: 'reporting.workload_plan.columns.action_type',
        key: 'action_type'
      },
      {
        label: 'projects.form.deliverables.planning.workload',
        key: 'workload'
      },
      {
        label: 'projects.form.deliverables.planning.start_date',
        key: 'start_date'
      },
      {
        label: 'projects.form.deliverables.planning.due_date',
        key: 'due_date'
      },
      {
        label: 'projects.form.deliverables.planning.status',
        key: 'status'
      },
      {
        label: 'projects.form.deliverables.planning.percentage',
        key: 'percentage'
      },
      // 10 actors
      ...Array.from({ length: 10 }, (_, i) => ({
        label: `reporting.workload_plan.columns.actor`,
        key: `actor_${i + 1}`,
        index: i + 1
      })),
      ...Array.from({ length: 10 }, (_, i) => ({
        label: `reporting.workload_plan.columns.group`,
        key: `group_${i + 1}`,
        index: i + 1
      }))
    ]
  };
};
export default headerByitems;
