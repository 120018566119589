import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card, Modal, Divider, Table, Button, message, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ManagePlanning from '../../routes/Projects/Projects/utilsProject/ManagePlanning/ManagePlanning';
import ShowActionModal from '../../routes/Projects/Projects/utilsProject/ManagePlanning/show/ShowActionModal';
import ModalForEventActivity from '../../routes/Dashboard/ModalForEventActivity';
import ModalConfirm from '../../routes/Dashboard/ModalConfirm';
import CreateUpdateForm from '../../routes/Projects/Projects/utilsProject/CreateUpdateForm';
import useAuthContext from '../../contexts/AuthContext';
import { getData } from '../../routes/Projects/Projects/utilsProject/ManagePlanning/utils';
import useActionColumns from './columns';
import { routes } from '../../utils/constants/routes';
import { DetailedActionModal } from './DetailedActionModal';

/**
 * Component for displaying and managing actions related to a project or version.
 *
 * @component
 * @param {string} id - The identifier of the project.
 * @param {Object|string} data - Selected version of managePlanning item.
 * @param {string} purpose - The purpose of the display (e.g., 'planned' or 'finished').
 * @param {string} title - The title of the component.
 * @param {string} status - The status of the project.
 * @param {Object} assignee - The assignee of the project.
 * @param {string} actorId - The identifier of the actor.
 * @param {Object} project - The project object.
 * @param {Function} updateResource - The function to update a resource.
 * @param {Function} createResource - The function to create a resource.
 * @param {Function} getProjects - The function to get all projects.
 * @param {boolean} loading - The loading state.
 * @param {Function} setLoading - The function to set the loading state.
 * @param {Function} getProject - The function to get a project by its id.
 * @param {Function} setPurpose - The function to set the purpose of the display.
 * @param {string} page - The page where this table will be displayed in.
 * @returns {JSX.Element} The Actions table.
 */
const Actions = ({
  id,
  data,
  purpose,
  title,
  status,
  assignee,
  actorId,
  project,
  updateResource,
  createResource,
  getProjects,
  loading,
  setLoading,
  getProject,
  setPurpose,
  page
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const modalRef = useRef();
  const { actionColumns } = useActionColumns();
  const [visible, setVisible] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [dataToModal, setDataToModal] = useState();
  const [dataUpdate, setDataUpdate] = useState();
  const [eventConfigData, setEventConfigData] = useState({});
  const [purposeArg, setPurposeArg] = useState();
  const [visibleEventActivity, setVisibleEventActivity] = useState(false);
  const [expendedActions, setExpendedActions] = useState(null);
  const history = useHistory();

  const handleCancel = () => {
    setVisibleForm(false);
    setPurpose(null);
    getProjects();
  };

  const config = {
    onGetResource: {
      setFields: () => {
        const { planning } = dataToModal;
        return {
          deliverables: {
            ...dataToModal,
            planning: planning
              ? {
                  ...planning,
                  assigned_actors: planning.assigned_actors?.map(
                    (assigned_actor) => assigned_actor._id
                  ),
                  assigned_groups: planning.assigned_groups?.map(
                    (assigned_group) => assigned_group._id
                  ),
                  macro_task: planning.macro_task._id,
                  start_date: planning.start_date
                    ? moment(planning?.start_date)
                    : undefined,
                  due_date: planning.due_date
                    ? moment(planning?.due_date)
                    : undefined
                }
              : null
          }
        };
      }
    }
  };

  const displayedData = getData(data?.content, purpose, actorId);
  const projectTaskId = data.content?.find((task) => task.planning.default)
    ?._id;

  const openModal = (eventConfigFromModal) => {
    setEventConfigData({ ...eventConfigFromModal, event: true });
    setPurposeArg('event');
    setVisibleForm(true);
  };

  const handleCancelForNewPlanning = () => {
    setEventConfigData();
    setPurposeArg();
    setVisibleForm(false);
    setDataToModal();
    setVisible(false);
    setPurpose();
    getProjects();
  };

  const handleSubmitNewPlanning = (values) => {
    if (status === 'VX') {
      if (purposeArg !== 'event') {
        modalRef.current.toggleModal(
          values,
          id,
          data,
          'manage_planning',
          'deliverables',
          handleCancelForNewPlanning
        );
      } else {
        const { event } = values;
        const result = {
          start_project: data.start_project,
          due_project: data.due_project,
          event,
          deliverables: [
            ...data.deliverables,
            eventConfigData.values.deliverables
          ]
        };
        createResource(
          result,
          id,
          eventConfigData.titleItem,
          null,
          handleCancelForNewPlanning
        );
      }
    } else {
      const resultDataToModalAndEvent = {
        deliverables: {
          ...values.deliverables
        }
      };
      updateResource(
        resultDataToModalAndEvent,
        data,
        'manage_planning',
        'deliverables',
        getProjects
      );
      setDataToModal();
      setVisible(false);
    }
  };

  const handleSubmitPlanning = async (values) => {
    if (
      (dataToModal &&
        moment(dataToModal.planning.due_date).format('DD-MM-YYYY') !==
          values.deliverables.planning.due_date.format('DD-MM-YYYY')) ||
      (dataToModal &&
        dataToModal.planning.start_date &&
        moment(dataToModal.planning.start_date).format('DD-MM-YYYY') !==
          values.deliverables.planning.start_date.format('DD-MM-YYYY'))
    ) {
      setDataUpdate(values);
      setVisibleEventActivity(true);
    } else {
      const resultDataToModalAndEvent = {
        deliverables: {
          ...values.deliverables,
          events: dataToModal ? dataToModal.events : null
        }
      };
      setVisible(false);
      setLoading(true);
      await updateResource(
        resultDataToModalAndEvent,
        data,
        'manage_planning',
        'deliverables',
        actorId ? () => getProject(id) : getProjects
      );
      setLoading(false);
      setDataToModal();
      message.success(t('projects.form.title.updated'));
    }
  };

  const ClickableTitle = (bool) => (
    <Row
      onClick={() =>
        page === 'dashboard'
          ? setExpendedActions(bool)
          : history.push(`${routes.DASHBOARD}`)
      }
      style={{ cursor: 'pointer' }}
      justify="center"
    >
      {t(`projects.details.${title}`)}
    </Row>
  );

  return data ? (
    <Card
      style={{
        boxShadow: 'none',
        color: 'var(--textColor)',
        fontSize: '1.1em',
        padding: 0,
        margin: '16px 0 24px 0'
      }}
      title={ClickableTitle(true)}
      extra={
        purpose === 'planned' &&
        assignee?._id === user._id && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setPurposeArg('createMatrice');
              setVisible(true);
            }}
          >
            Action
          </Button>
        )
      }
    >
      <Divider />
      <Table
        rowKey="_id"
        onRow={(record) => ({
          onClick: () => {
            if (page !== 'home') {
              setPurposeArg('edit');
              setDataToModal(record);
              setVisible(true);
            } else history.push(`${routes.DASHBOARD}`);
          }
        })}
        size="small"
        columns={actionColumns}
        dataSource={displayedData}
        scroll={{ x: 500 }}
      />
      {expendedActions && (
        <DetailedActionModal
          expendedActions={expendedActions}
          setExpendedActions={setExpendedActions}
          addButton={purpose === 'planned' && assignee?._id === user._id}
          displayedData={displayedData}
          projectTaskId={projectTaskId}
          setPurposeArg={setPurposeArg}
          setVisible={setVisible}
          setDataToModal={setDataToModal}
          ClickableTitle={ClickableTitle}
        />
      )}
      {visibleEventActivity && (
        <ModalForEventActivity
          data={dataToModal}
          dataUpdate={dataUpdate}
          visible={visibleEventActivity}
          setVisible={setVisibleEventActivity}
          setDataToModal={setDataToModal}
          setVisibleForm={setVisible}
          idProject={id}
          version={data}
        />
      )}
      {visible && assignee?._id === user._id && !actorId ? (
        <Modal
          closable
          width={1600}
          destroyOnClose
          centered
          visible={visible}
          onOk={() => {
            setDataToModal();
            setVisible(false);
          }}
          onCancel={() => {
            setDataToModal();
            setVisible(false);
          }}
          footer={false}
        >
          <ManagePlanning
            dataItems={dataToModal}
            project={project}
            config={purposeArg === 'edit' ? config : null}
            handleCancel={() => {
              setVisible(false);
            }}
            handleSubmit={
              purposeArg === 'create'
                ? handleSubmitNewPlanning
                : handleSubmitPlanning
            }
            purposeArg={actorId ? 'actor' : purposeArg}
            version={data}
          />
        </Modal>
      ) : (
        <ShowActionModal
          dataItems={dataToModal}
          visible={visible}
          setVisible={setVisible}
          updateResource={updateResource}
        />
      )}
      <ModalConfirm destroyOnClose openModal={openModal} ref={modalRef} />
      <Modal
        title={t(`projects.form.items.${eventConfigData?.titleItem}.title`)}
        visible={visibleForm}
        footer={false}
        destroyOnClose
        onCancel={handleCancel}
        width={1200}
      >
        <CreateUpdateForm
          purpose={purposeArg}
          resource="projects"
          title="event"
          handleCancel={handleCancel}
          projectId={id}
          data={eventConfigData?.data}
          config={null}
          index={null}
          fieldsName="event"
          handleSubmit={handleSubmitNewPlanning}
          isLoading={loading}
        />
      </Modal>
    </Card>
  ) : (
    <Card
      style={{
        background: 'var(--itemHoverBackground)',
        boxShadow: 'none',
        color: 'var(--textColor)',
        fontSize: '1.1em',
        padding: 0,
        margin: '16px 0 24px 0',
        borderRadius: 50
      }}
      title={t(`projects.details.${title}`)}
    >
      <Divider />
    </Card>
  );
};

Actions.propTypes = {
  id: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  purpose: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  assignee: PropTypes.shape({ _id: PropTypes.string.isRequired }),
  actorId: PropTypes.string,
  project: PropTypes.shape({}).isRequired,
  updateResource: PropTypes.func,
  createResource: PropTypes.func,
  getProjects: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  getProject: PropTypes.func,
  setPurpose: PropTypes.func,
  page: PropTypes.string
};

Actions.defaultProps = {
  id: null,
  data: null,
  title: null,
  purpose: null,
  status: null,
  assignee: null,
  actorId: null,
  loading: false,
  updateResource: () => {},
  createResource: () => {},
  getProjects: () => {},
  setLoading: () => {},
  getProject: () => {},
  setPurpose: () => {},
  page: ''
};

export default Actions;
