import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Form } from 'antd';

const RadioForChooseTypeOfFile = ({
  value,
  onChange,
  keyTab,
  disabledFormats
}) => {
  const typesList = ['docx', 'xlsx', 'pdf'];
  return (
    <Form.Item name={['extension_file']}>
      <Radio.Group
        key={keyTab}
        value={value}
        onChange={onChange}
        buttonStyle="solid"
        style={{
          margin: '0px 0px 10px 0px',
          width: '100%'
        }}
      >
        {typesList.map((e) => (
          <Radio disabled={disabledFormats.includes(e)} key={e} value={e}>
            {e}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};
RadioForChooseTypeOfFile.propTypes = {
  keyTab: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabledFormats: PropTypes.arrayOf(PropTypes.string)
};
RadioForChooseTypeOfFile.defaultProps = {
  keyTab: null,
  value: null,
  onChange: () => {},
  disabledFormats: []
};
export default RadioForChooseTypeOfFile;
