import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Layout,
  Card,
  Skeleton,
  Divider,
  Button,
  Modal,
  Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import Actions from '../../components/Actions/Actions';
import IndicatorsDashboard from './IndicatorsDashboard';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import MascotteRouge from '../../assets/images/PersoRouge.png';
import MascotteOrange from '../../assets/images/PersoOrange.png';
import MascotteVert from '../../assets/images/PersoVert.png';
import useDashboardContext from '../../contexts/DashboardContext';
import useHandleResize from '../../utils/HandleResize';
import ManagePlanning from '../Projects/Projects/utilsProject/ManagePlanning/ManagePlanning';
import MemoComponent from './MemoComponent';
import RiskMatrixChart from '../../components/Widgets/RiskMatrixChart';
import EventHeadband from './EventHeadband/EventHeadband';
import {
  getItemVersion,
  getLastItemVersion
} from '../Projects/Projects/utilsProject/ManageVersion/utils';

/**
 * Component for displaying the dashboard tab of a project.
 *
 * @component
 * @param {Object|string} data - The data of the project.
 * @param {JSX.Element} indicators - The JSX element containing project indicators ( duration, cost, utility, feration).
 * @param {function} openModal - The function to open a modal.
 * @param {function} getProject - The function to get a project by its id.
 * @param {boolean} refreshGantt - The boolean to refresh the Gantt chart when the tab is changed.
 * @returns {JSX.Element} The DashboardTab component.
 */
const DashboardTab = ({
  data,
  indicators,
  openModal,
  getProject,
  refreshGantt
}) => {
  const { t } = useTranslation();
  const {
    getCloseEvent,
    eventClose,
    getProjects,
    updateResource,
    createResource,
    loading,
    setLoading
  } = useDashboardContext();
  const { Content } = Layout;
  const { dispatchAPI } = useAuthContext();
  const [dataChart, setDataChart] = useState();
  const { message: errorMessage } = useErrorMessage();
  const [visible, setVisible] = useState(false);
  const [dataToModal, setDataToModal] = useState();
  const [taskId, setTaskId] = useState(null);

  const configNote = {
    onGetResource: {
      setFields: (dataConfig) => ({
        note: dataConfig.note
      })
    }
  };

  const config = {
    onGetResource: {
      setFields: () => {
        const { planning } = dataToModal;
        return {
          deliverables: {
            ...dataToModal,
            planning: planning
              ? {
                  ...dataToModal.planning,
                  assigned_actors: planning.assigned_actors?.map(
                    (assigned_actor) => assigned_actor._id
                  ),
                  assigned_groups: planning.assigned_groups?.map(
                    (assigned_group) => assigned_group._id
                  ),
                  macro_task: planning.macro_task?._id,
                  start_date: planning.start_date
                    ? moment(planning?.start_date)
                    : undefined,
                  due_date: planning.due_date
                    ? moment(planning?.due_date)
                    : undefined
                }
              : null
          }
        };
      }
    }
  };
  const mascotteArray = [MascotteRouge, MascotteOrange, MascotteVert];
  const { width } = useHandleResize();
  const isReductScreen = width < 1301;

  const IndicatorWrapper = styled(Content)`
    padding: 15px;
    background: var(--clientColor);
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    color: var(--textColorSecondary);
  `;

  const handleMascotteClick = async () => {
    const mascot_state =
      data?.mascot_state === 2 ? 0 : parseInt(data?.mascot_state, 10) + 1;
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${data?._id}`,
        body: { mascot_state }
      });
      await getProject();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getDataChart = () => {
    const risks = getLastItemVersion(data.risks).content;
    if (risks) {
      const itemRisks = [];
      risks.forEach((risk) => {
        const { criticality } = risk;
        itemRisks.push({
          item: risk.type_of_risk ? risk.type_of_risk.toString() : 'N/A',
          id: risk._id,
          type: 'Risques',
          value: Math.round(criticality)
        });
      });
      setDataChart(itemRisks);
    }

    return true;
  };
  useEffect(() => {
    if (data && data._id) {
      getCloseEvent(data._id);
      getDataChart();
    }
  }, [data]);

  const handleSubmitPlanning = async (values) => {
    try {
      setLoading(true);
      setVisible(false);
      await updateResource(
        values,
        getLastItemVersion(data?.manage_planning),
        'manage_planning',
        'deliverables',
        getProjects
      );
      setLoading(false);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (taskId) {
      const result = getItemVersion(data.manage_planning, -1)?.content.find(
        (ele) => ele._id === taskId
      );
      setDataToModal(result);
      setVisible(true);
    }
  }, [taskId]);

  return data ? (
    <Spin spinning={loading}>
      <Row gutter={16}>
        <Col span={isReductScreen ? 24 : 20}>
          <IndicatorWrapper>{indicators}</IndicatorWrapper>
        </Col>
        <Col
          span={isReductScreen ? 0 : 4}
          style={{
            display: isReductScreen ? 'none' : 'flex',
            justifySelf: 'end'
          }}
        >
          <Button
            onClick={() => handleMascotteClick()}
            style={{
              background: '#D3D3D3',
              borderRadius: 5,
              height: '100%',
              width: '100%',
              marginLeft: 16,
              boxShadow: '0 2px 3px rgb(0 0 0 / 30%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              alt="Mascotte de CitizenCan"
              src={mascotteArray[data?.mascot_state]}
              height="150px"
            />
          </Button>
        </Col>
      </Row>
      <EventHeadband
        t={t}
        eventClose={eventClose}
        getProjects={getProjects}
        updateResource={updateResource}
        configNote={configNote}
        data={data}
        openModal={openModal}
      />
      <Row justify="space-between" gutter={24}>
        {[
          { title: 'action_done', purpose: 'finished' },
          { title: 'action_to_do', purpose: 'planned' }
        ].map((action) => (
          <Col xl={12} sm={24} xs={24}>
            <Actions
              id={data?._id}
              title={action.title}
              purpose={action.purpose}
              data={getItemVersion(data.manage_planning, -1)}
              assignee={data.assignee}
              project={data}
              createResource={createResource}
              updateResource={updateResource}
              getProjects={getProjects}
              setLoading={setLoading}
              page="dashboard"
            />
          </Col>
        ))}
      </Row>
      <Col span={24}>
        <MemoComponent
          data={data.memos}
          idProject={data._id}
          getProject={getProject}
        />
      </Col>
      <Row justify="space-between">
        <Col xxl={17} xl={16} lg={20} md={24} sm={24} xs={24}>
          <IndicatorsDashboard
            setTaskId={setTaskId}
            data={data}
            refreshGantt={refreshGantt}
          />
          <Modal
            closable
            width={1000}
            destroyOnClose
            visible={visible}
            onOk={() => {
              setDataToModal();
              setVisible(false);
            }}
            onCancel={() => {
              setDataToModal();
              setVisible(false);
            }}
            footer={false}
          >
            {dataToModal && (
              <ManagePlanning
                project={data}
                version={getItemVersion(data.manage_planning, -1)}
                purposeArg="edit"
                dataItems={dataToModal}
                projectId={data._id}
                config={config}
                handleCancel={() => {
                  setVisible(false);
                }}
                handleSubmit={handleSubmitPlanning}
              />
            )}
          </Modal>
        </Col>
        <Col style={{ textAlign: 'center' }} xxl={6} xl={12} sm={24} xs={24}>
          <Card
            style={{ width: '100%', minHeight: 400, margin: '16px 0 24px' }}
            title={t('projects.risk_matrix')}
          >
            <Divider />
            {dataChart && dataChart.length > 0 ? (
              <Row justify="center">
                <RiskMatrixChart dataChart={dataChart} />
              </Row>
            ) : null}
          </Card>
        </Col>
      </Row>
    </Spin>
  ) : (
    <Skeleton />
  );
};

DashboardTab.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  indicators: PropTypes.element,
  openModal: PropTypes.func,
  getProject: PropTypes.func,
  refreshGantt: PropTypes.bool
};

DashboardTab.defaultProps = {
  data: null,
  indicators: null,
  openModal: null,
  getProject: null,
  refreshGantt: false
};

export default DashboardTab;
