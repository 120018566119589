import React from 'react';
import { Redirect } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import ManagersReporting from './ManagersReporting';
import { routes } from '../../utils/constants/routes';
import { AdminsReporting } from './AdminsReporting';

const ShowReporting = () => {
  const { user } = useAuthContext();

  switch (user.role) {
    case 'admins:PERIMETER_ADMIN':
      return <AdminsReporting />;
    case 'managers:PROJECT_MANAGER':
      return <ManagersReporting />;
    default:
      return <Redirect to={routes.HOME} />;
  }
};

export default ShowReporting;
