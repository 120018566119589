import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, Skeleton, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { getItemVersion } from '../Projects/Projects/utilsProject/ManageVersion/utils';
import { GanttChart } from '../../components/Widgets/Gantt/Planning/PlanningGantt';

/**
 * Component for displaying project indicators on a dashboard.
 *
 * @component
 * @param {Object} data - The project data.
 * @param {Function} setTaskId - The function to set the task ID.
 * @param {string} actorId - The actor ID is provided if the user is an actor. It is used to filter the actors' actions.
 * @param {boolean} refreshGantt - The boolean to refresh the Gantt chart when the tab is changed.
 * @returns {JSX.Element} - A Gantt chart.
 */
const IndicatorsDashboard = ({ data, setTaskId, actorId, refreshGantt }) => {
  const { t } = useTranslation();
  const lastManangePlanningVersion = getItemVersion(data?.manage_planning, -1);

  return data ? (
    <Row justify="space-between" gutter={16}>
      <Col xxl={24} sm={24} xs={24}>
        <Card
          title={t('projects.gantt')}
          style={{ width: '100%', margin: '16px 0 24px' }}
        >
          <Divider />
          <GanttChart
            data={lastManangePlanningVersion.content}
            onTaskClick={setTaskId}
            actorId={actorId}
            refreshGantt={refreshGantt}
          />
        </Card>
      </Col>
    </Row>
  ) : (
    <Skeleton />
  );
};

IndicatorsDashboard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  setTaskId: PropTypes.func,
  actorId: PropTypes.string,
  refreshGantt: PropTypes.bool
};

IndicatorsDashboard.defaultProps = {
  data: null,
  setTaskId: null,
  actorId: '',
  refreshGantt: false
};

export default IndicatorsDashboard;
