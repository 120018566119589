import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useDetailedActionColumns from './detailedColumns';

/**
 * DetailedActionTable component
 *
 * @component
 * @param {Array} data - Array of tasks
 * @param {String} projectTaskId - Id of the defaut project task
 * @param {Function} setPurposeArg - Function to set the purpose argument
 * @param {Function} setDataToModal - Function to set the data to the modal
 * @param {Function} setVisible - Function to set the visibility of the modal
 * @returns {React.Component} <DetailedActionTable />
 */
export const DetailedActionTable = ({
  data,
  projectTaskId,
  setPurposeArg,
  setDataToModal,
  setVisible
}) => {
  const { t } = useTranslation();
  const [groupByMacroTask, setgroupByMacroTask] = useState(false);
  const { detailedActionColumns } = useDetailedActionColumns(
    projectTaskId,
    groupByMacroTask
  );

  const getSubTasks = (macroTask) => {
    return data.filter(
      (task) => task.planning.macro_task._id === macroTask._id
    );
  };

  const [expandedKeys, setExpandedKeys] = useState([]);

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedKeys([...expandedKeys, record._id]);
    } else {
      setExpandedKeys(expandedKeys.filter((key) => key !== record._id));
    }
  };

  const fomattedData = data
    .filter((task) => {
      return task.planning.macro_task._id === projectTaskId;
    })
    .map((task) => ({
      ...task,
      children:
        task.planning.action_type === 'MACRO_TASK' &&
        getSubTasks(task).map((subTask) => ({
          ...subTask
        }))
    }));

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Table
          rowKey="_id"
          onRow={(record) => ({
            onClick: () => {
              setPurposeArg('edit');
              setDataToModal(record);
              setVisible(true);
            }
          })}
          size="small"
          columns={detailedActionColumns}
          dataSource={groupByMacroTask ? fomattedData : data}
          expandable={
            groupByMacroTask && {
              expandedRowKeys: expandedKeys,
              onExpand: handleExpand
            }
          }
          pagination={false}
        />
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Checkbox
            checked={groupByMacroTask}
            onChange={(e) => setgroupByMacroTask(e.target.checked)}
          >
            {t('dashboard.actions.group_by_macro_task')}
          </Checkbox>
        </Row>
      </Col>
    </Row>
  );
};

DetailedActionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  projectTaskId: PropTypes.string,
  setPurposeArg: PropTypes.func,
  setDataToModal: PropTypes.func,
  setVisible: PropTypes.func,
  record: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

DetailedActionTable.defaultProps = {
  data: [],
  projectTaskId: '',
  setPurposeArg: () => {},
  setDataToModal: () => {},
  setVisible: () => {},
  record: {}
};
