/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Layout } from 'antd';
import styled from 'styled-components';
import RiskMatrixChart from '../../../components/Widgets/RiskMatrixChart';
import Gantt from '../../../components/Widgets/Gantt';
import ShowIndicators from '../../home/ShowIndicators';
import { versionToIndex } from '../../../utils/constants/constantes';
import Time from '../../home/ChartIndicators/Time';
import Cost from '../../home/ChartIndicators/Cost';
import Utility from '../../home/ChartIndicators/Utility';
import Federation from '../../home/ChartIndicators/Federation';
import planComTochart from '../../Projects/Projects/ItemsDisplay/PlanComDisplay/PlanComTabs/TabPanes/GanttTabPane/dataToChart';
import { checkActionActor } from '../../Projects/Projects/utilsProject/ManagePlanning/utils';
import { GanttChart } from '../../../components/Widgets/Gantt/Planning/PlanningGantt';
import {
  getItemVersion,
  getLastItemVersion
} from '../../Projects/Projects/utilsProject/ManageVersion/utils';

const { Content } = Layout;
const ChartHidden = ({ data, version, type, selectedDates, selectedActor }) => {
  const IndicatorWrapper = styled(Content)`
    padding: 15px;
    background: var(--clientColor);
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    color: var(--textColorSecondary);
  `;
  const riskDataChart = (risk) => {
    const itemRisks = [];
    (risk?.risk_list || []).forEach((e) => {
      const { criticality } = e;
      itemRisks.push({
        item: e.type_of_risk ? e.type_of_risk.toString() : 'N/A',
        id: e._id,
        type: 'Risques',
        value: Math.round(criticality)
      });
    });
    return itemRisks;
  };
  const formatDataToChartFederation = (dataFederation) => {
    return planComTochart(
      dataFederation.filter((el) => {
        if (selectedActor) {
          return [el.transmitter, el.target, el.in_charge_of].includes(
            selectedActor
          );
        }
        return el;
      })
    );
  };

  const formatDataToChartGanttGap = (dataGap) => {
    const chartData = [];
    (dataGap?.content || [])
      .filter((el) => {
        if (selectedActor) {
          return checkActionActor(el, selectedActor);
        }
        return el;
      })
      .filter((el) => {
        if (selectedDates?.length > 0 && el.planning) {
          if (
            moment(el.planning.start_date) >=
              moment(selectedDates[0]).startOf('day') &&
            moment(el.planning.start_date) <=
              moment(selectedDates[1]).endOf('day')
          )
            return el;
          if (
            moment(el.planning.due_date) >=
              moment(selectedDates[0]).startOf('day') &&
            moment(el.planning.due_date) <=
              moment(selectedDates[1]).endOf('day')
          )
            return el;
          return null;
        }
        return el;
      })
      .forEach((deliverable) => {
        if (deliverable.events) {
          (deliverable?.events || []).forEach((e) => {
            const { old_start_date, old_due_date } = e;
            const color = deliverable.planning?.color || '#000';
            if ((old_start_date, old_due_date !== null)) {
              chartData.push({
                id: e._id,
                task: `${e._id + deliverable.description}`,
                start_date:
                  old_start_date !== undefined
                    ? moment(old_start_date).format('YYYY-MM-DD')
                    : moment(old_due_date).format('YYYY-MM-DD'),
                due_date: moment(old_due_date).format('YYYY-MM-DD'),
                description: e.description,
                comment: e.comment,
                color
              });
            }
          });
        }
      });
    return chartData;
  };

  const formatValueToChart = (dataToFormat, title) => {
    switch (title) {
      case 'risk':
        return riskDataChart(
          dataToFormat?.risks[
            version !== 'VX'
              ? versionToIndex[version]
              : dataToFormat?.risks?.length - 1
          ]
        );
      case 'gantt_planning':
        return (version === 'VX'
          ? getLastItemVersion(dataToFormat.manage_planning)
          : getItemVersion(
              dataToFormat.manage_planning,
              versionToIndex[version]
            )
        )?.content;
      case 'gantt_federation':
        return formatDataToChartFederation(
          dataToFormat.plan_com[
            version !== 'VX'
              ? versionToIndex[version]
              : dataToFormat.plan_com.length - 1
          ].content
        );
      case 'gantt_gap':
        return formatDataToChartGanttGap(
          dataToFormat.manage_planning[
            version !== 'VX'
              ? versionToIndex[version]
              : dataToFormat.manage_planning.length - 1
          ]
        );
      default:
        return dataToFormat;
    }
  };

  const listIndicators = {
    project_state: [
      {
        chart: (
          <IndicatorWrapper>
            <ShowIndicators project={data} />
          </IndicatorWrapper>
        ),
        title: 'indicators'
      },
      {
        chart: (
          <RiskMatrixChart
            dataChart={formatValueToChart(data, 'risk')}
            width={500}
            height={700}
          />
        ),
        title: 'risk'
      },
      {
        chart: (
          <GanttChart
            data={formatValueToChart(data, 'gantt_planning')}
            selectedDates={selectedDates}
            actorId={selectedActor}
          />
        ),
        title: 'gantt_planning'
      },
      {
        chart: (
          <Gantt
            data={formatValueToChart(data, 'gantt_federation')}
            lengthForFederation={
              data.plan_com[
                version !== 'VX'
                  ? versionToIndex[version]
                  : data.plan_com.length - 1
              ]?.deliverables_plan_com?.length
            }
            defaultLengthSize={1500}
            selectedDates={selectedDates}
          />
        ),
        title: 'gantt_federation'
      }
    ],
    evolution_project: [
      {
        chart: (
          <Time
            data={formatValueToChart(data, 'time')}
            width={700}
            purpose="time"
          />
        ),
        title: 'time'
      },
      {
        chart: <Cost data={formatValueToChart(data, 'cost')} purpose="cost" />,
        title: 'cost'
      },
      {
        chart: (
          <Utility
            data={formatValueToChart(data, 'utility')}
            purpose="utility"
          />
        ),
        title: 'utility'
      },
      {
        chart: (
          <Federation
            data={formatValueToChart(data, 'federation')}
            purpose="federation"
          />
        ),
        title: 'federation'
      }
    ],
    federation: [
      {
        chart: (
          <Gantt
            data={formatValueToChart(data, 'gantt_federation')}
            lengthForFederation={
              data.plan_com[
                version !== 'VX'
                  ? versionToIndex[version]
                  : data.plan_com.length - 1
              ]?.content?.length
            }
            defaultLengthSize={1500}
            selectedDates={selectedDates}
          />
        ),
        title: 'gantt_federation'
      }
    ],
    planning: [
      {
        chart: (
          <GanttChart
            data={formatValueToChart(data, 'gantt_planning')}
            selectedDates={selectedDates}
            actorId={selectedActor}
          />
        ),
        title: 'gantt_planning'
      }
    ],
    gap: [
      {
        chart: (
          <Gantt
            data={formatValueToChart(data, 'gantt_gap')}
            defaultLengthSize={1500}
            lengthForFederation={formatValueToChart(data, 'gantt_gap').length}
            selectedDates={selectedDates}
            purpose="gantt_gap"
          />
        ),
        title: 'gantt_gap'
      }
    ],
    meeting_report: []
  };

  useEffect(() => {}, [version]);

  return data !== null && type !== null ? (
    <div
      style={{
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      {(listIndicators[type] || []).map((Chart) => (
        <div
          key={`${Chart.title}`}
          style={{ position: 'absolute' }}
          id={`${type}${Chart.title}`}
        >
          {Chart.chart}
        </div>
      ))}
    </div>
  ) : null;
};
ChartHidden.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  version: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ChartHidden.defaultProps = {
  data: null,
  version: null
};

export default ChartHidden;
