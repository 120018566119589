import React from 'react';
import PropTypes from 'prop-types';
import { Form, DatePicker, Divider } from 'antd';
import RadioForChooseTypeOfFile from '../widgetsReporting/RadioForChooseTypeOfFile';
import getActorsAndGroupsSelect from '../../Projects/Projects/fieldsItems/Inputs/getActorsAndGroupsSelect';
import { VersionRadio } from '../widgetsReporting/VersionRadio';

const { RangePicker } = DatePicker;
const FederationSelected = ({
  t,
  keyTab,
  version,
  current_status,
  actorsItem,
  setselectedDates,
  setselectedActor
}) => {
  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        height: 'auto'
      }}
    >
      <RadioForChooseTypeOfFile keyTab={keyTab} />
      <Divider />
      <Form.Item name={['version']} initialValue={version}>
        <VersionRadio status={current_status} />
      </Form.Item>
      <Form.Item name={['dates']}>
        <RangePicker
          onChange={(dates) =>
            dates ? setselectedDates(dates) : setselectedDates([])
          }
        />
      </Form.Item>
      <Form.Item label={t('reporting.select_actor')} name={['actor']}>
        {getActorsAndGroupsSelect(actorsItem, null, null, (actor) =>
          actor ? setselectedActor(actor) : setselectedActor(null)
        )}
      </Form.Item>
    </div>
  );
};

FederationSelected.propTypes = {
  t: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  current_status: PropTypes.string,
  keyTab: PropTypes.string,
  actorsItem: PropTypes.shape({}).isRequired,
  setselectedDates: PropTypes.func,
  setselectedActor: PropTypes.func
};
FederationSelected.defaultProps = {
  current_status: null,
  keyTab: null,
  setselectedDates: () => {},
  setselectedActor: () => {}
};

export default FederationSelected;
