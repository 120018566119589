import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin, Tabs, Modal, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import DashboardResourceTab from './DashboardResourceTab';
import useAuthContext from '../../contexts/AuthContext';
import ButtonCreateProject from '../Projects/Projects/utilsProject/ButtonCreateProject';
import useErrorMessage from '../../utils/ErrorMessage';
import ShowIndicators from '../home/ShowIndicators';
import CreateUpdateForm from '../Projects/Projects/utilsProject/CreateUpdateForm';
import ManageFederation from '../Projects/Projects/utilsProject/ManageFederation';
import setIconStatus from '../../utils/constants/setIconStatus';
import useDashboardContext from '../../contexts/DashboardContext';

const { Title } = Typography;

/**
 * The Dashboard component displays project-related information and controls.
 *
 * @component
 * @returns {JSX.Element} - The Dashboard component.
 */
const Dashboard = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI, updateUser } = useAuthContext();
  const {
    getProjects,
    projects,
    setLoading,
    loading,
    setProjects
  } = useDashboardContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const [loadingChangeTab, setLoadingChangeTab] = useState(false);
  const [visibleFederation, setVisibleFederation] = useState(false);
  const [modalConfig, setModalConfig] = useState();
  const [title, setTitle] = useState();
  const [purpose, setPurpose] = useState();
  const [fieldsName, setFieldName] = useState();
  const [KeyTab, setKeyTab] = useState(id);
  const [config, setConfig] = useState();
  const [refreshGantt, setRefreshGantt] = useState(false);
  const { TabPane } = Tabs;

  useEffect(() => {
    (async () => {
      await getProjects();
    })();
  }, [getProjects]);

  const HeaderDashboard = () => {
    return (
      <Row
        justify="space-around"
        align="middle"
        gutter={[16, 48]}
        style={{ paddingRight: '16px' }}
      >
        <Col style={{ marginLeft: 16 }}>
          <ButtonCreateProject />
        </Col>
      </Row>
    );
  };

  const updateFederation = async (idFed, values) => {
    setLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${idFed}`,
        body: values
      });
      getProjects();
      setLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
      setLoading(false);
    }
  };

  const getKeyTab = (activekey) => {
    setLoadingChangeTab(true);
    updateUser({ selected_project: activekey });

    const resultFilter = projects.filter((ele) => ele._id !== activekey);
    resultFilter.unshift(projects.filter((el) => el._id === activekey)[0]);
    setProjects(resultFilter);
    setKeyTab(activekey);
    setLoadingChangeTab(false);
    setRefreshGantt(!refreshGantt);
  };

  const handleCancelFederation = async () => {
    setVisibleFederation(false);
    await getProjects();
  };
  const openModal = (dataT, titleModal, index, action, fields, configArg) => {
    setModalConfig(dataT);
    setTitle(titleModal);
    setPurpose(action);
    setFieldName(fields);
    setConfig(configArg);
    setVisibleFederation(true);
  };

  const handleSubmit = (values, dataArg) => {
    if (title === 'note') {
      handleCancelFederation();
      updateFederation(dataArg._id, values);
    }
  };

  return projects ? (
    <>
      <Modal
        title={t(`projects.form.items.${title}.title`)}
        visible={visibleFederation}
        width={1000}
        footer={false}
        onCancel={handleCancelFederation}
      >
        {title === 'membership' ? (
          <ManageFederation
            dataItems={modalConfig}
            projectId={modalConfig?._id}
            config={config}
            handleCancel={handleCancelFederation}
          />
        ) : (
          <CreateUpdateForm
            purpose={purpose}
            resource="projects"
            title={title}
            handleCancel={handleCancelFederation}
            projectId={modalConfig?._id}
            data={modalConfig}
            config={config}
            fieldsName={fieldsName}
            handleSubmit={handleSubmit}
            isLoading={loading}
          />
        )}
      </Modal>
      <div style={{ fontSize: '21px', marginBottom: 10 }}>
        {t(`projects.title_chief`)}
      </div>
      <Spin spinning={loadingChangeTab}>
        <Tabs
          onChange={(activeKey) => getKeyTab(activeKey)}
          tabBarExtraContent={HeaderDashboard()}
          defaultActiveKey={KeyTab}
        >
          {projects.length > 0 ? (
            projects.map((project) => {
              return (
                <TabPane
                  tab={setIconStatus(project.name, project.status, true, 29)}
                  key={project._id}
                >
                  <DashboardResourceTab
                    data={project || null}
                    getProject={getProjects}
                    indicators={
                      <ShowIndicators
                        key={project._id}
                        project={project}
                        openModal={openModal}
                        vue="assignee"
                      />
                    }
                    openModal={openModal}
                    refreshGantt={refreshGantt}
                  />
                </TabPane>
              );
            })
          ) : (
            <Row justify="center">
              <Title style={{ textAlign: 'center' }} level={2}>
                {t('errors.message.no_projects')}
              </Title>
            </Row>
          )}
        </Tabs>
      </Spin>
    </>
  ) : (
    <Spin />
  );
};

export default Dashboard;
