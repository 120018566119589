/**
 * Format the selectable projects to be displayed in the select component
 *
 * @function
 * @param {object[]} projects - List of projects
 * @param {string} selectedVersion - Selected version
 * @returns {object[]} Formatted projects
 */
const formatProjectsOptions = (projects, selectedVersion) =>
  projects?.map((project) => ({
    value: project._id,
    label: `${project.name} (${project.status})`,
    disabled: selectedVersion && project.status < selectedVersion
  }));

/**
 * Format the selectable managers to be displayed in the select component
 *
 * @function
 * @param {object[]} managers - List of managers
 * @returns {object[]} Formatted managers
 */
const formatMangersOptions = (managers) =>
  managers?.map((manager) => ({
    value: manager._id,
    label: `${manager.first_name} ${manager.last_name}`
  }));

/**
 * Get the workload plan data: perimeters, managers and projects
 *
 * @function
 * @param {function} dispatchAPI - Dispatch API function
 * @returns {Promise} Promise object represents the workload plan data
 */
export const getWorkloadPlanData = async (dispatchAPI) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/reporting/data/workload_plan`
    });
    return Promise.resolve({
      data,
      options: {
        perimeters: data.perimeters.map((p) => ({
          value: p._id,
          label: p.name
        })),
        managers: formatMangersOptions(data.managers),
        projects: formatProjectsOptions(data.projects)
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Filter the projects based on the selected managers and perimeters
 *
 * @function
 * @param {object} data - Workload plan data
 * @param {object} values - Selected values
 * @returns {object[]} Filtered projects
 */
const filterProjects = (data, values) =>
  data?.projects?.filter(
    (project) =>
      (values.managers?.length
        ? values.managers.includes(project.assignee._id)
        : true) &&
      (values.perimeters?.length
        ? values.perimeters.some((perimeter) =>
            data.perimeters
              .find((p) => p._id === perimeter)
              .projects.includes(project._id)
          )
        : true)
  );

/**
 * Handle the workload plan change
 *
 * @function
 * @param {object} change - The field that has changed
 * @param {object} values - The form values
 * @param {object} form - Form object
 * @param {object} reportingData - Reporting data
 * @param {function} setReportingData - Set the reporting data
 * @returns {void}
 */
const handleWorkloadPlanChange = (
  change,
  values,
  form,
  reportingData,
  setReportingData
) => {
  const projects = filterProjects(reportingData.data, values);

  if (change.version) {
    form.setFieldsValue({
      projects: values.projects?.filter((project) =>
        projects.find((p) => p._id === project && p.status >= change.version)
      )
    });

    setReportingData({
      data: reportingData.data,
      options: {
        ...reportingData.options,
        projects: formatProjectsOptions(projects, change.version)
      }
    });
  } else if (change.managers) {
    form.setFieldsValue({
      projects: values.projects?.filter((project) =>
        projects.find((p) => p._id === project)
      )
    });

    setReportingData({
      data: reportingData.data,
      options: {
        ...reportingData.options,
        projects: formatProjectsOptions(projects, values.version)
      }
    });
  } else if (change.perimeters) {
    const managers = values.perimeters?.length
      ? reportingData.data.managers.filter((manager) =>
          change.perimeters.some((perimeter) =>
            manager.perimeters.includes(perimeter)
          )
        )
      : reportingData.data.managers;

    form.setFieldsValue({
      managers: values.managers?.filter((manager) =>
        managers.some((m) => m._id === manager)
      ),
      projects: values.projects?.filter((project) =>
        projects.find((p) => p._id === project)
      )
    });

    setReportingData({
      data: reportingData.data,
      options: {
        ...reportingData.options,
        managers: formatMangersOptions(managers),
        projects: formatProjectsOptions(projects, values.version)
      }
    });
  }
};

/**
 * Get the number of selected projects (for workload plan)
 *
 * @function
 * @param {object[]} selectedProjects - Selected projects
 * @param {object} reportingData - Reporting data
 * @returns {number} Number of selected projects
 */
export const getProjectsCount = (selectedProjects, reportingData) =>
  (selectedProjects?.length
    ? selectedProjects
    : reportingData?.options?.projects
  )?.filter((option) => !option.disabled).length;

/**
 * Handle the report change
 *
 * @function
 * @param {object} change - The field that has changed
 * @param {object} values - The form values
 * @param {object} form - Form object
 * @param {object} reportingData - Reporting data
 * @param {function} setReportingData - Set the reporting data
 * @returns {void}
 */
export const handleReportChange = (
  change,
  values,
  form,
  reportingData,
  setReportingData
) => {
  if (change.type)
    form.setFieldsValue({
      extension_file: change.type === 'workload_plan' ? 'xlsx' : 'pdf'
    });

  switch (values.type) {
    case 'workload_plan':
      handleWorkloadPlanChange(
        change,
        values,
        form,
        reportingData,
        setReportingData
      );
      break;
    default:
      break;
  }
  return null;
};
