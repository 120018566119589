import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Divider, Row } from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { EditIcon } from '../../../../../../../../utils/constants/customIcon';
import ActionCard from './ActionCard';
import { GanttChart } from '../../../../../../../../components/Widgets/Gantt/Planning/PlanningGantt';

/**
 * GanttTabPane is a React component that displays a Gantt chart and associated project elements.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.project - Project details.
 * @param {Object} props.version - Selected managePlanning version details.
 * @param {boolean} props.role - User's role in the project.
 * @param {function} props.setVisibleModal - Function to display a modal.
 * @param {function} props.setDataDeliverables - Function to set deliverables data.
 * @param {function} props.setGanttConfig - Function to configure the Gantt chart.
 * @returns {JSX.Element} - The React JSX element representing the GanttTabPane component.
 */
const GanttTabPane = ({
  project,
  version,
  role,
  setVisibleModal,
  setDataDeliverables,
  setGanttConfig
}) => {
  const [dataChart, setDataChart] = useState();

  const openFormGantt = (idDeliv, deliverable) => {
    if ((idDeliv, deliverable)) {
      setGanttConfig(true);
      setDataChart(null);
      const { start_date, due_date, ...rest } = deliverable.planning;
      setDataDeliverables({
        ...deliverable,
        planning: {
          ...rest,
          start_date: start_date !== undefined ? moment(start_date) : undefined,
          due_date: moment(due_date),
          macro_task: deliverable.planning?.macro_task?._id
        }
      });
      setVisibleModal(true);
    }
  };

  const closeFormGantt = () => {
    setDataChart();
    setVisibleModal(false);
  };

  return (
    <Card
      style={{
        overflowY: 'hidden',
        width: '100%',
        overflowX: 'auto',
        minHeight: 'fit-content'
      }}
    >
      <Row justify="space-between">
        <Col xl={24} sm={24}>
          {dataChart
            ? version?.content?.map((e) =>
                dataChart === e._id ? (
                  <Card
                    style={{
                      zIndex: 5,
                      marginLeft: '30%',
                      marginTop: '5%',
                      width: 500,
                      border: '0.4px solid',
                      position: 'absolute'
                    }}
                    extra={
                      <Row justify="space-between">
                        {role ? (
                          <Button
                            disabled={project?.status === 'close'}
                            size="small"
                            type="link"
                            style={{
                              color: 'var(--secondaryColor)',
                              top: -2
                            }}
                            onClick={() => openFormGantt(e._id, e)}
                          >
                            <EditIcon />
                          </Button>
                        ) : null}

                        <Button
                          onClick={() => closeFormGantt()}
                          style={{
                            color: 'var(--errorColor)',
                            background: 'transparent',
                            border: 'none',
                            marginLeft: 5,
                            boxShadow: 'none'
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </Row>
                    }
                    key={e._id}
                    title={e.description}
                  >
                    <Divider />
                    <ActionCard action={e} />
                  </Card>
                ) : null
              )
            : null}
          <GanttChart data={version.content} onTaskClick={setDataChart} />
        </Col>
      </Row>
    </Card>
  );
};

GanttTabPane.propTypes = {
  project: PropTypes.shape({ status: PropTypes.string }).isRequired,
  version: PropTypes.shape({ content: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  role: PropTypes.bool,
  setVisibleModal: PropTypes.func.isRequired,
  setDataDeliverables: PropTypes.func.isRequired,
  setGanttConfig: PropTypes.func.isRequired
};

GanttTabPane.defaultProps = {
  role: false
};

export default GanttTabPane;
