import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import gantt from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Col, DatePicker, Radio, Row } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import {
  filterDataByDates,
  formatData,
  ganttTranslations,
  getScreenShotOfChart,
  getTooltip
} from './utils';

const { RangePicker } = DatePicker;

/**
 * Component for displaying a Gantt chart.
 *
 * @component
 * @param {Object} data - The list of manage planning items.
 * @param {Function} onTaskClick - The function to call when a task is clicked.
 * @param {Date[]} selectedDates - The selected dates to filter the data for repporting filters.
 * @param {string} actorId - The actor ID is provided if the user is an actor or for repporting filters.
 * @param {boolean} refreshGantt - The boolean to refresh the Gantt chart when the tab is changed.
 * @returns {JSX.Element} - A Gantt chart.
 */
export const GanttChart = ({
  data,
  onTaskClick,
  selectedDates,
  actorId,
  refreshGantt
}) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState(null);
  const [currentScale, setCurrentScale] = useState(1);
  const [resetGantt, setResetGantt] = useState(false);

  const ganttContainer = useRef(null);

  const scaleLabels = ['day', 'week', 'month', 'year'];
  const scales = [
    [
      { unit: 'month', step: 1, format: '%F %Y' },
      {
        unit: 'day',
        step: 1,
        format: '%D %d',
        css: (date) => {
          if (date.getDay() === 0 || date.getDay() === 6) {
            return 'weekend';
          }
          return '';
        }
      }
    ],
    [
      { unit: 'month', step: 1, format: '%F %Y' },
      {
        unit: 'week',
        step: 1,
        format: (date) => {
          return `Sem ${gantt.date.getWeek(date)}`;
        }
      }
    ],
    [
      { unit: 'year', step: 1, format: '%Y' },
      { unit: 'month', step: 1, format: '%F %Y' }
    ],
    [{ unit: 'year', step: 1, format: '%Y' }]
  ];

  const formattedData = filterDataByDates(formatData(data, actorId), dates);

  const handleScaleChange = (e) => {
    setCurrentScale(e.target.value);
  };

  useEffect(() => {
    gantt.config.columns = [
      {
        name: 'text',
        label: t('projects.form.description'),
        tree: true,
        resize: true,
        width: 200
      }
    ];

    gantt.locale = ganttTranslations;
    gantt.config.date_format = '%Y-%m-%d';
    gantt.config.scales = scales[currentScale];
    gantt.config.start_date = formattedData[0]?.start_date - 1;
    gantt.config.end_date = formattedData[0]?.end_date + 1;

    gantt.templates.rightside_text = (start, end, task) => {
      if (task.type === gantt.config.types.milestone) {
        return task.text;
      }
      return '';
    };

    gantt.templates.timeline_cell_class = (task, date) => {
      if (currentScale === 0 && (date.getDay() === 0 || date.getDay() === 6)) {
        return 'weekend';
      }
      return '';
    };

    gantt.templates.task_class = (start, end, task) => {
      if (task.type === 'project') return 'custom_task_border';
      return '';
    };

    gantt.templates.tooltip_text = getTooltip(t);

    gantt.plugins({ tooltip: true });

    gantt.attachEvent('onTaskClick', (id, e) => {
      if (e.target.classList.contains('gantt_task_content')) onTaskClick(id);
      return true;
    });

    gantt.attachEvent('onEmptyClick', () => {
      onTaskClick();
    });

    if (ganttContainer.current) {
      gantt.init(ganttContainer.current);
      gantt.parse({ data: formattedData });
    }

    return () => {
      gantt.clearAll();
    };
  }, [formattedData, currentScale]);

  useEffect(() => {
    setDates(selectedDates);
  }, [selectedDates]);

  useEffect(() => {
    setCurrentScale(1);
    setTimeout(() => {
      // simulate click on week button to fix gantt chart display /!\ TO FIX
      const weekButton = document.querySelector('#week-radio-button');
      if (weekButton) {
        weekButton.click();
      }
    }, 500);
  }, [refreshGantt]);

  return (
    <Row gutter={[16, 16]} u>
      <Col span={24}>
        <Row gutter={[16, 16]} align="middle" justify="space-evenly">
          <Col style={{ textAlign: 'center' }}>
            <p className="plannig-gantt-text">
              {t('projects.show.gantt.filter_description')}
            </p>
            <RangePicker
              syle={{ width: 250 }}
              defaultValue={dates}
              value={dates}
              onChange={(value) => setDates(value)}
            />
          </Col>
          <Col style={{ textAlign: 'center' }}>
            <p className="plannig-gantt-text">
              {t('projects.show.gantt.scale')}
            </p>
            <Radio.Group
              value={currentScale}
              onChange={handleScaleChange}
              optionType="button"
              buttonStyle="solid"
            >
              {scaleLabels.map((scale, index) => (
                <Radio.Button
                  key={scale}
                  value={index}
                  id={scale === 'week' ? 'week-radio-button' : undefined}
                  onClick={() => setResetGantt(!resetGantt)}
                >
                  {t(`global.dates.types.${scale}`)}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
          <Col style={{ textAlign: 'center' }} span={4}>
            <CameraOutlined
              style={{ fontSize: 25, color: 'var(--primaryColor)' }}
              onClick={getScreenShotOfChart}
            />
          </Col>
        </Row>
      </Col>
      <div
        id="planning_gantt"
        ref={ganttContainer}
        style={{ width: '100%', height: '500px' }}
      />
    </Row>
  );
};

GanttChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onTaskClick: PropTypes.func,
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  actorId: PropTypes.string,
  refreshGantt: PropTypes.bool
};

GanttChart.defaultProps = {
  data: [],
  onTaskClick: () => {},
  selectedDates: null,
  actorId: '',
  refreshGantt: false
};
