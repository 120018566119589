import React from 'react';
import PropTypes from 'prop-types';
import { Col, Divider, Form, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import RadioForChooseTypeOfFile from '../widgetsReporting/RadioForChooseTypeOfFile';
import { VersionRadio } from '../widgetsReporting/VersionRadio';
import { listVersion } from '../../../utils/constants/constantes';

/**
 * WorkloadPlan component
 *
 * @component
 * @param {object} props - WorkloadPlan props
 * @param {object} props.filtersOptions - Filters options
 * @param {string} props.version - Version
 * @param {boolean} props.loading - Loading
 * @returns {React.Component} <WorkloadPlan />
 */
export const WorkloadPlan = ({ filtersOptions, version, loading }) => {
  const { t } = useTranslation();

  const filters = ['perimeters', 'managers', 'projects'];

  const count = {
    perimeters: filtersOptions?.perimeters?.length,
    managers: filtersOptions?.managers?.length,
    projects: filtersOptions?.projects?.filter((option) => !option.disabled)
      .length
  };

  return (
    <Col span={24}>
      <Spin spinning={loading}>
        <RadioForChooseTypeOfFile disabledFormats={['pdf', 'docx']} />
        <Divider />
        <Form.Item name={['version']} initialValue={version}>
          <VersionRadio />
        </Form.Item>
        {filters.map((filter) => (
          <Col span={24} key={filter}>
            <Form.Item name={[filter]}>
              <Select
                allowClear
                placeholder={`${t(`reporting.filters.${filter}`)}${
                  count[filter] ? ` - (${count[filter]})` : ''
                }`}
                mode="multiple"
                style={{ width: '60%' }}
                options={filtersOptions?.[filter]}
              />
            </Form.Item>
          </Col>
        ))}
      </Spin>
    </Col>
  );
};

WorkloadPlan.propTypes = {
  filtersOptions: PropTypes.shape({
    perimeters: PropTypes.array,
    managers: PropTypes.array,
    projects: PropTypes.array
  }),
  version: PropTypes.string,
  loading: PropTypes.bool
};

WorkloadPlan.defaultProps = {
  filtersOptions: {
    perimeters: [],
    managers: [],
    projects: []
  },
  version: listVersion[0],
  loading: false
};
